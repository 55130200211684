import { ReactNode } from 'react';
import ReactDOM from 'react-dom';

const portalEl = document.getElementById('portal-root');

interface PortalProps {
  children: ReactNode;
}

const Portal = ({ children }: PortalProps) => {
  if (!portalEl) {
    return null;
  }

  return ReactDOM.createPortal(children, portalEl);
};

export default Portal;
