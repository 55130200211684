/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MipLayerResponseDto
 */
export interface MipLayerResponseDto {
    /**
     * 
     * @type {number}
     * @memberof MipLayerResponseDto
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof MipLayerResponseDto
     */
    layer?: string;
}

/**
 * Check if a given object implements the MipLayerResponseDto interface.
 */
export function instanceOfMipLayerResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function MipLayerResponseDtoFromJSON(json: any): MipLayerResponseDto {
    return MipLayerResponseDtoFromJSONTyped(json, false);
}

export function MipLayerResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): MipLayerResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'layer': !exists(json, 'layer') ? undefined : json['layer'],
    };
}

export function MipLayerResponseDtoToJSON(value?: MipLayerResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'layer': value.layer,
    };
}

