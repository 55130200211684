/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SecretsStorageGithubAuthRoleV1Dto
 */
export interface SecretsStorageGithubAuthRoleV1Dto {
    /**
     * Name of the organization that owns the repository
     * @type {string}
     * @memberof SecretsStorageGithubAuthRoleV1Dto
     */
    organization: SecretsStorageGithubAuthRoleV1DtoOrganizationEnum;
    /**
     * Name of the github repository (case sensitive)
     * @type {string}
     * @memberof SecretsStorageGithubAuthRoleV1Dto
     */
    repository: string;
    /**
     * Name of created github authentication roles
     * @type {string}
     * @memberof SecretsStorageGithubAuthRoleV1Dto
     */
    readonly roleName?: string;
}


/**
 * @export
 */
export const SecretsStorageGithubAuthRoleV1DtoOrganizationEnum = {
    MaerskGlobal: 'MAERSK_GLOBAL',
    MaerskGlobalTest: 'MAERSK_GLOBAL_TEST',
    Maersktech: 'MAERSKTECH'
} as const;
export type SecretsStorageGithubAuthRoleV1DtoOrganizationEnum = typeof SecretsStorageGithubAuthRoleV1DtoOrganizationEnum[keyof typeof SecretsStorageGithubAuthRoleV1DtoOrganizationEnum];


/**
 * Check if a given object implements the SecretsStorageGithubAuthRoleV1Dto interface.
 */
export function instanceOfSecretsStorageGithubAuthRoleV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "organization" in value;
    isInstance = isInstance && "repository" in value;

    return isInstance;
}

export function SecretsStorageGithubAuthRoleV1DtoFromJSON(json: any): SecretsStorageGithubAuthRoleV1Dto {
    return SecretsStorageGithubAuthRoleV1DtoFromJSONTyped(json, false);
}

export function SecretsStorageGithubAuthRoleV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretsStorageGithubAuthRoleV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'organization': json['organization'],
        'repository': json['repository'],
        'roleName': !exists(json, 'roleName') ? undefined : json['roleName'],
    };
}

export function SecretsStorageGithubAuthRoleV1DtoToJSON(value?: SecretsStorageGithubAuthRoleV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'organization': value.organization,
        'repository': value.repository,
    };
}

