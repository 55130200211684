/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ExecHealthCheckV1Dto } from './ExecHealthCheckV1Dto';
import {
    ExecHealthCheckV1DtoFromJSON,
    ExecHealthCheckV1DtoFromJSONTyped,
    ExecHealthCheckV1DtoToJSON,
} from './ExecHealthCheckV1Dto';
import type { HealthCheckConfigV1Dto } from './HealthCheckConfigV1Dto';
import {
    HealthCheckConfigV1DtoFromJSON,
    HealthCheckConfigV1DtoFromJSONTyped,
    HealthCheckConfigV1DtoToJSON,
} from './HealthCheckConfigV1Dto';
import type { HttpHealthCheckV1Dto } from './HttpHealthCheckV1Dto';
import {
    HttpHealthCheckV1DtoFromJSON,
    HttpHealthCheckV1DtoFromJSONTyped,
    HttpHealthCheckV1DtoToJSON,
} from './HttpHealthCheckV1Dto';
import type { TcpHealthCheckV1Dto } from './TcpHealthCheckV1Dto';
import {
    TcpHealthCheckV1DtoFromJSON,
    TcpHealthCheckV1DtoFromJSONTyped,
    TcpHealthCheckV1DtoToJSON,
} from './TcpHealthCheckV1Dto';

/**
 * 
 * @export
 * @interface HealthCheckSpecV1Dto
 */
export interface HealthCheckSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof HealthCheckSpecV1Dto
     */
    type?: HealthCheckSpecV1DtoTypeEnum;
    /**
     * 
     * @type {HttpHealthCheckV1Dto}
     * @memberof HealthCheckSpecV1Dto
     */
    http?: HttpHealthCheckV1Dto;
    /**
     * 
     * @type {TcpHealthCheckV1Dto}
     * @memberof HealthCheckSpecV1Dto
     */
    tcp?: TcpHealthCheckV1Dto;
    /**
     * 
     * @type {ExecHealthCheckV1Dto}
     * @memberof HealthCheckSpecV1Dto
     */
    exec?: ExecHealthCheckV1Dto;
    /**
     * 
     * @type {HealthCheckConfigV1Dto}
     * @memberof HealthCheckSpecV1Dto
     */
    config?: HealthCheckConfigV1Dto;
}


/**
 * @export
 */
export const HealthCheckSpecV1DtoTypeEnum = {
    Http: 'HTTP',
    Tcp: 'TCP',
    Exec: 'EXEC'
} as const;
export type HealthCheckSpecV1DtoTypeEnum = typeof HealthCheckSpecV1DtoTypeEnum[keyof typeof HealthCheckSpecV1DtoTypeEnum];


/**
 * Check if a given object implements the HealthCheckSpecV1Dto interface.
 */
export function instanceOfHealthCheckSpecV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function HealthCheckSpecV1DtoFromJSON(json: any): HealthCheckSpecV1Dto {
    return HealthCheckSpecV1DtoFromJSONTyped(json, false);
}

export function HealthCheckSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): HealthCheckSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': !exists(json, 'type') ? undefined : json['type'],
        'http': !exists(json, 'http') ? undefined : HttpHealthCheckV1DtoFromJSON(json['http']),
        'tcp': !exists(json, 'tcp') ? undefined : TcpHealthCheckV1DtoFromJSON(json['tcp']),
        'exec': !exists(json, 'exec') ? undefined : ExecHealthCheckV1DtoFromJSON(json['exec']),
        'config': !exists(json, 'config') ? undefined : HealthCheckConfigV1DtoFromJSON(json['config']),
    };
}

export function HealthCheckSpecV1DtoToJSON(value?: HealthCheckSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'http': HttpHealthCheckV1DtoToJSON(value.http),
        'tcp': TcpHealthCheckV1DtoToJSON(value.tcp),
        'exec': ExecHealthCheckV1DtoToJSON(value.exec),
        'config': HealthCheckConfigV1DtoToJSON(value.config),
    };
}

