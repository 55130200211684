import { useState } from 'react';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import { yupResolver } from '@hookform/resolvers/yup';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McModal } from '@maersk-global/mds-react-wrapper/components-core/mc-modal';
import { useForm } from 'react-hook-form';
import useSWRMutation from 'swr/mutation';
import * as yup from 'yup';
import { getMacUrl } from '@/helpers/urls';
import { useApp } from '@/hooks/useApp';
import Input from '@/components/Input/Input';
import { Key } from 'swr';
import { appPage, defaultErrorMessages } from '@text';
import ErrorNotification from '@/components/ErrorNotification/ErrorNotification';
import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';

interface FormValues {
  macAppId: string;
}

const schema = yup.object().shape({
  macAppId: yup.string().required(defaultErrorMessages.required),
});

export const MacAppId = () => {
  const [isOpen, setIsOpen] = useState(false);
  const userInAppTeam = useUserIsAppOwner();
  const { data: app, mutate: mutateApp } = useApp();
  const client = useMttsApiClient();

  const form = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      macAppId: '',
    },
  });

  const { trigger: updateApp, isMutating: isUpdating } = useSWRMutation(
    ['app', app?.metadata.name],
    async (_key: Key, { arg }: { arg: FormValues }) => {
      if (!app || !arg.macAppId) return;

      return await client.patchMacIdForApplication(
        { macId: arg.macAppId },
        app.metadata.name,
      );
    },
  );

  const onSubmit = async (data: FormValues) => {
    await updateApp(data);
    await mutateApp();
    setIsOpen(false);
  };
  const { setError } = useError();

  const handleClose = () => {
    form.reset({ macAppId: '' });
    form.clearErrors();
    setError(undefined);
    setIsOpen(false);
  };

  if (app?.spec?.macAppId) {
    return (
      <a target="_blank" href={getMacUrl(app.spec.macAppId)} rel="noreferrer">
        {app.spec.macAppId}
      </a>
    );
  }

  return (
    <div>
      {userInAppTeam ? (
        <>
          <FormWrapper form={form} schema={schema}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <McModal
                open={isOpen}
                opened={() => setIsOpen(true)}
                closed={handleClose}
                fit="small"
                width="400px"
                heading={appPage.macAppId.modalHeading}
              >
                <FormInputGroup>
                  <ErrorNotification />
                  <Input
                    name="macAppId"
                    text={{
                      label: appPage.macAppId.input.label,
                      placeholder: appPage.macAppId.input.placeholder,
                      hint: appPage.macAppId.input.hint,
                    }}
                  />
                </FormInputGroup>
                <McButton
                  slot="secondaryAction"
                  variant="outlined"
                  appearance="primary"
                  onClick={handleClose}
                  type="button"
                >
                  {appPage.macAppId.cancelButton}
                </McButton>
                <McButton
                  type="submit"
                  slot="primaryAction"
                  loading={isUpdating}
                >
                  {appPage.macAppId.submitButton}
                </McButton>
              </McModal>
            </form>
          </FormWrapper>
          <McButton
            variant="plain"
            trailingicon="plus"
            appearance="primary"
            padding="none"
            fit="small"
            onClick={() => setIsOpen(true)}
          >
            {appPage.macAppId.setMacIdButton}
          </McButton>
        </>
      ) : (
        <div>{appPage.macAppId.noMacId}</div>
      )}
    </div>
  );
};
