import AppPageHeader from '@/components/AppPageHeader/AppPageHeader';
import PageBody from '@/components/PageBody/PageBody';
import { TopicForm } from '@/components/EventsProcessing/TopicForm/TopicForm';
import { useParams, useNavigate } from 'react-router-dom';
import useSWRMutation from 'swr/mutation';
import classes from './EventsProcessingCreateTopicPage.module.scss';
import { PipelineV1Dto, TopicV1Dto } from '@@/generated/openapi';
import { waitForPipeline } from '@/helpers/waitForPipeline';
import { getTopicUrl } from '@/helpers/urls';

const extractTopicIdFromPipelineStep = (pipeline: PipelineV1Dto) => {
  const topicId = pipeline.status?.steps?.find(
    (step) => step.code === 'WaitForTopicCreation',
  )?.message?.context?.topicId;
  return topicId;
};

const EventsProcessingCreateTopicPage = () => {
  const client = useApiClient();
  const { app, env } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const { trigger } = useSWRMutation(
    ['topic-create'],
    async (_key: [string], { arg: data }: { arg: TopicV1Dto }) =>
      await waitForPipeline<TopicV1Dto>(
        () => client.createTopicV1(data, app ?? '', env ?? ''),
        (id) => client.getPipeline(id),
        (pipeline) =>
          client.getTopicV1(
            app ?? '',
            env ?? '',
            extractTopicIdFromPipelineStep(pipeline as PipelineV1Dto) ?? '',
          ),
      ),
  );

  const handleSubmit = async (data: TopicV1Dto) => {
    try {
      setIsLoading(true);
      const response = await trigger(data);
      if (response?.id) {
        navigate(getTopicUrl({ topic: response.id, app, env }));
      }
    } catch (error) {
      console.error('Failed to create topic:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <AppPageHeader />
      <PageBody className={classes.pageBody} title="Create Kafka Topic" padding>
        <TopicForm
          onSubmit={handleSubmit}
          isLoading={isLoading}
          mode="create"
        />
      </PageBody>
    </>
  );
};

export default EventsProcessingCreateTopicPage;
