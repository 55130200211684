import classes from './Modal.module.scss';
import { ReactNode } from 'react';
import Portal from '@/components/Portal/Portal';
import { CSSTransition } from 'react-transition-group';
import { closeModal, openModal } from '@/helpers/modal';
import ErrorNotification from '@/components/ErrorNotification/ErrorNotification';
import useError from '@/hooks/useError';
import { FloatingFocusManager, useFloating } from '@floating-ui/react';

interface SharedModalProps {
  children: ReactNode;
  className?: string;
}

interface ModalProps extends SharedModalProps {
  open: boolean;
  closed: () => void;
  className?: string;
  closeOnOverlayClick?: boolean;
  persistError?: boolean;
}

export const ModalHeader = ({ children, className = '' }: SharedModalProps) => {
  return (
    <h2 className={`${classes.header} mds-headline--small ${className}`}>
      {children}
    </h2>
  );
};

export const ModalFooter = ({ children, className = '' }: SharedModalProps) => {
  return <div className={`${classes.footer} ${className}`}>{children}</div>;
};

export const ModalBody = ({ children, className = '' }: SharedModalProps) => {
  return (
    <div className={`${classes.body} ${className}`}>
      <ErrorNotification />
      {children}
    </div>
  );
};

export const Modal = ({
  children,
  open,
  closed,
  className,
  closeOnOverlayClick = false,
  persistError = false,
}: ModalProps) => {
  const { context, refs } = useFloating({ open });
  const { setError } = useError();
  const nodeRef = useRef(null);

  useEffect(() => {
    return closeModal;
  }, []);

  useEffect(() => {
    const escapeHandler = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        closed();
      }
    };

    window.addEventListener('keydown', escapeHandler);

    return () => window.removeEventListener('keydown', escapeHandler);
  }, [closed]);

  return (
    <Portal>
      <CSSTransition
        nodeRef={nodeRef}
        appear
        mountOnEnter
        unmountOnExit
        in={open}
        timeout={200}
        classNames={{
          enter: classes.modalEnter,
          enterActive: classes.modalEnterActive,
          exit: classes.modalExit,
          exitActive: classes.modalExitActive,
        }}
        onExited={() => {
          !persistError && setError();
          closeModal();
        }}
        onEntering={() => {
          openModal();
        }}
      >
        <div
          ref={nodeRef}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div
            className={`${classes.overlay}`}
            onClick={() => closeOnOverlayClick && closed()}
          >
            {open && (
              <FloatingFocusManager
                context={context}
                order={['floating', 'content']}
              >
                <div
                  className={`${classes.modal} ${className ?? ''}`}
                  ref={refs.setFloating}
                >
                  {children}
                </div>
              </FloatingFocusManager>
            )}
          </div>
        </div>
      </CSSTransition>
    </Portal>
  );
};

Modal.Header = ModalHeader;
Modal.Footer = ModalFooter;
Modal.Body = ModalBody;

export default Modal;
