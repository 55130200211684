/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { DataSecurityClassificationV1Dto } from './DataSecurityClassificationV1Dto';
import {
    DataSecurityClassificationV1DtoFromJSON,
    DataSecurityClassificationV1DtoFromJSONTyped,
    DataSecurityClassificationV1DtoToJSON,
} from './DataSecurityClassificationV1Dto';
import type { ResourceRefV1Dto } from './ResourceRefV1Dto';
import {
    ResourceRefV1DtoFromJSON,
    ResourceRefV1DtoFromJSONTyped,
    ResourceRefV1DtoToJSON,
} from './ResourceRefV1Dto';
import type { TopicCleanupPolicyV1Dto } from './TopicCleanupPolicyV1Dto';
import {
    TopicCleanupPolicyV1DtoFromJSON,
    TopicCleanupPolicyV1DtoFromJSONTyped,
    TopicCleanupPolicyV1DtoToJSON,
} from './TopicCleanupPolicyV1Dto';

/**
 * 
 * @export
 * @interface TopicV1AllOfSpecDto
 */
export interface TopicV1AllOfSpecDto {
    /**
     * 
     * @type {DataSecurityClassificationV1Dto}
     * @memberof TopicV1AllOfSpecDto
     */
    dataSecurityClassification?: DataSecurityClassificationV1Dto;
    /**
     * Flag to indicate if the topic could be shared across multiple applications. Defaults to true.
     * If the topic is shared, the description field is required.
     * 
     * @type {boolean}
     * @memberof TopicV1AllOfSpecDto
     */
    isSharable?: boolean;
    /**
     * 
     * @type {ResourceRefV1Dto}
     * @memberof TopicV1AllOfSpecDto
     */
    keySchema?: ResourceRefV1Dto;
    /**
     * 
     * @type {ResourceRefV1Dto}
     * @memberof TopicV1AllOfSpecDto
     */
    valueSchema?: ResourceRefV1Dto;
    /**
     * The number of Kafka partitions for the topic.
     * Once increased, it cannot be decreased. Defaults to 3.
     * 
     * @type {number}
     * @memberof TopicV1AllOfSpecDto
     */
    partitionNumber?: number;
    /**
     * The time, in days, to retain messages in the topic. Defaults to 24 hours.
     * 
     * @type {number}
     * @memberof TopicV1AllOfSpecDto
     */
    retentionHours?: number;
    /**
     * ADVANCED. Don't change unless you know what you're doing.
     * The number of Kafka replicas for the topic. Defaults to 3.
     * 
     * @type {number}
     * @memberof TopicV1AllOfSpecDto
     */
    replicationFactor?: number;
    /**
     * ADVANCED. Don't change unless you know what you're doing.
     * The size of a Kafka segment in bytes. Defaults to 1 GiB.
     * 
     * @type {number}
     * @memberof TopicV1AllOfSpecDto
     */
    segmentBytes?: number;
    /**
     * 
     * @type {TopicCleanupPolicyV1Dto}
     * @memberof TopicV1AllOfSpecDto
     */
    cleanupPolicy?: TopicCleanupPolicyV1Dto;
}

/**
 * Check if a given object implements the TopicV1AllOfSpecDto interface.
 */
export function instanceOfTopicV1AllOfSpecDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function TopicV1AllOfSpecDtoFromJSON(json: any): TopicV1AllOfSpecDto {
    return TopicV1AllOfSpecDtoFromJSONTyped(json, false);
}

export function TopicV1AllOfSpecDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TopicV1AllOfSpecDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dataSecurityClassification': !exists(json, 'dataSecurityClassification') ? undefined : DataSecurityClassificationV1DtoFromJSON(json['dataSecurityClassification']),
        'isSharable': !exists(json, 'isSharable') ? undefined : json['isSharable'],
        'keySchema': !exists(json, 'keySchema') ? undefined : ResourceRefV1DtoFromJSON(json['keySchema']),
        'valueSchema': !exists(json, 'valueSchema') ? undefined : ResourceRefV1DtoFromJSON(json['valueSchema']),
        'partitionNumber': !exists(json, 'partitionNumber') ? undefined : json['partitionNumber'],
        'retentionHours': !exists(json, 'retentionHours') ? undefined : json['retentionHours'],
        'replicationFactor': !exists(json, 'replicationFactor') ? undefined : json['replicationFactor'],
        'segmentBytes': !exists(json, 'segmentBytes') ? undefined : json['segmentBytes'],
        'cleanupPolicy': !exists(json, 'cleanupPolicy') ? undefined : TopicCleanupPolicyV1DtoFromJSON(json['cleanupPolicy']),
    };
}

export function TopicV1AllOfSpecDtoToJSON(value?: TopicV1AllOfSpecDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dataSecurityClassification': DataSecurityClassificationV1DtoToJSON(value.dataSecurityClassification),
        'isSharable': value.isSharable,
        'keySchema': ResourceRefV1DtoToJSON(value.keySchema),
        'valueSchema': ResourceRefV1DtoToJSON(value.valueSchema),
        'partitionNumber': value.partitionNumber,
        'retentionHours': value.retentionHours,
        'replicationFactor': value.replicationFactor,
        'segmentBytes': value.segmentBytes,
        'cleanupPolicy': TopicCleanupPolicyV1DtoToJSON(value.cleanupPolicy),
    };
}

