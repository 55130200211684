/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';
import type { PlatformMetaResponseDto } from './PlatformMetaResponseDto';
import {
    PlatformMetaResponseDtoFromJSON,
    PlatformMetaResponseDtoFromJSONTyped,
    PlatformMetaResponseDtoToJSON,
} from './PlatformMetaResponseDto';

/**
 * 
 * @export
 * @interface PortfolioResponseDto
 */
export interface PortfolioResponseDto {
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    portfolioId?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    portfolioName?: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof PortfolioResponseDto
     */
    owners?: Set<OwnerDto>;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof PortfolioResponseDto
     */
    eaContacts?: Set<OwnerDto>;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    shortName?: string;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    status?: PortfolioResponseDtoStatusEnum;
    /**
     * 
     * @type {Set<PlatformMetaResponseDto>}
     * @memberof PortfolioResponseDto
     */
    platforms?: Set<PlatformMetaResponseDto>;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    createdBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioResponseDto
     */
    createdDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    modifiedBy?: string;
    /**
     * 
     * @type {Date}
     * @memberof PortfolioResponseDto
     */
    modifiedDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof PortfolioResponseDto
     */
    canEdit?: boolean;
    /**
     * 
     * @type {string}
     * @memberof PortfolioResponseDto
     */
    portfolioType?: string;
}


/**
 * @export
 */
export const PortfolioResponseDtoStatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive',
    Rejected: 'Rejected',
    Deleted: 'Deleted'
} as const;
export type PortfolioResponseDtoStatusEnum = typeof PortfolioResponseDtoStatusEnum[keyof typeof PortfolioResponseDtoStatusEnum];


/**
 * Check if a given object implements the PortfolioResponseDto interface.
 */
export function instanceOfPortfolioResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function PortfolioResponseDtoFromJSON(json: any): PortfolioResponseDto {
    return PortfolioResponseDtoFromJSONTyped(json, false);
}

export function PortfolioResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortfolioResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'portfolioId': !exists(json, 'portfolioId') ? undefined : json['portfolioId'],
        'portfolioName': !exists(json, 'portfolioName') ? undefined : json['portfolioName'],
        'owners': !exists(json, 'owners') ? undefined : (new Set((json['owners'] as Array<any>).map(OwnerDtoFromJSON))),
        'eaContacts': !exists(json, 'eaContacts') ? undefined : (new Set((json['eaContacts'] as Array<any>).map(OwnerDtoFromJSON))),
        'shortName': !exists(json, 'shortName') ? undefined : json['shortName'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'platforms': !exists(json, 'platforms') ? undefined : (new Set((json['platforms'] as Array<any>).map(PlatformMetaResponseDtoFromJSON))),
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'createdDate': !exists(json, 'createdDate') ? undefined : (new Date(json['createdDate'])),
        'modifiedBy': !exists(json, 'modifiedBy') ? undefined : json['modifiedBy'],
        'modifiedDate': !exists(json, 'modifiedDate') ? undefined : (new Date(json['modifiedDate'])),
        'canEdit': !exists(json, 'canEdit') ? undefined : json['canEdit'],
        'portfolioType': !exists(json, 'portfolioType') ? undefined : json['portfolioType'],
    };
}

export function PortfolioResponseDtoToJSON(value?: PortfolioResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'portfolioId': value.portfolioId,
        'portfolioName': value.portfolioName,
        'owners': value.owners === undefined ? undefined : (Array.from(value.owners as Set<any>).map(OwnerDtoToJSON)),
        'eaContacts': value.eaContacts === undefined ? undefined : (Array.from(value.eaContacts as Set<any>).map(OwnerDtoToJSON)),
        'shortName': value.shortName,
        'status': value.status,
        'platforms': value.platforms === undefined ? undefined : (Array.from(value.platforms as Set<any>).map(PlatformMetaResponseDtoToJSON)),
        'createdBy': value.createdBy,
        'createdDate': value.createdDate === undefined ? undefined : (value.createdDate.toISOString()),
        'modifiedBy': value.modifiedBy,
        'modifiedDate': value.modifiedDate === undefined ? undefined : (value.modifiedDate.toISOString()),
        'canEdit': value.canEdit,
        'portfolioType': value.portfolioType,
    };
}

