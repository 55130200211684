/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EnvVarSpecV1Dto } from './EnvVarSpecV1Dto';
import {
    EnvVarSpecV1DtoFromJSON,
    EnvVarSpecV1DtoFromJSONTyped,
    EnvVarSpecV1DtoToJSON,
} from './EnvVarSpecV1Dto';
import type { HealthCheckSpecV1Dto } from './HealthCheckSpecV1Dto';
import {
    HealthCheckSpecV1DtoFromJSON,
    HealthCheckSpecV1DtoFromJSONTyped,
    HealthCheckSpecV1DtoToJSON,
} from './HealthCheckSpecV1Dto';
import type { RevisionSpecV1AllOfSecretGroupRefsDto } from './RevisionSpecV1AllOfSecretGroupRefsDto';
import {
    RevisionSpecV1AllOfSecretGroupRefsDtoFromJSON,
    RevisionSpecV1AllOfSecretGroupRefsDtoFromJSONTyped,
    RevisionSpecV1AllOfSecretGroupRefsDtoToJSON,
} from './RevisionSpecV1AllOfSecretGroupRefsDto';
import type { RevisionTemplateSpecV1ObservabilityDto } from './RevisionTemplateSpecV1ObservabilityDto';
import {
    RevisionTemplateSpecV1ObservabilityDtoFromJSON,
    RevisionTemplateSpecV1ObservabilityDtoFromJSONTyped,
    RevisionTemplateSpecV1ObservabilityDtoToJSON,
} from './RevisionTemplateSpecV1ObservabilityDto';
import type { RevisionTemplateSpecV1ResourcesDto } from './RevisionTemplateSpecV1ResourcesDto';
import {
    RevisionTemplateSpecV1ResourcesDtoFromJSON,
    RevisionTemplateSpecV1ResourcesDtoFromJSONTyped,
    RevisionTemplateSpecV1ResourcesDtoToJSON,
} from './RevisionTemplateSpecV1ResourcesDto';
import type { ScalingSpecV1Dto } from './ScalingSpecV1Dto';
import {
    ScalingSpecV1DtoFromJSON,
    ScalingSpecV1DtoFromJSONTyped,
    ScalingSpecV1DtoToJSON,
} from './ScalingSpecV1Dto';
import type { SecretFileMountSpecV1Dto } from './SecretFileMountSpecV1Dto';
import {
    SecretFileMountSpecV1DtoFromJSON,
    SecretFileMountSpecV1DtoFromJSONTyped,
    SecretFileMountSpecV1DtoToJSON,
} from './SecretFileMountSpecV1Dto';

/**
 * 
 * @export
 * @interface RevisionSpecV1Dto
 */
export interface RevisionSpecV1Dto {
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1Dto
     */
    region: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1Dto
     */
    containerImageName: string;
    /**
     * 
     * @type {ScalingSpecV1Dto}
     * @memberof RevisionSpecV1Dto
     */
    replicaScaling: ScalingSpecV1Dto;
    /**
     * 
     * @type {RevisionTemplateSpecV1ResourcesDto}
     * @memberof RevisionSpecV1Dto
     */
    resources: RevisionTemplateSpecV1ResourcesDto;
    /**
     * 
     * @type {HealthCheckSpecV1Dto}
     * @memberof RevisionSpecV1Dto
     */
    healthCheck: HealthCheckSpecV1Dto;
    /**
     * 
     * @type {RevisionTemplateSpecV1ObservabilityDto}
     * @memberof RevisionSpecV1Dto
     */
    observability: RevisionTemplateSpecV1ObservabilityDto;
    /**
     * 
     * @type {number}
     * @memberof RevisionSpecV1Dto
     */
    exposedPort?: number;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionSpecV1Dto
     */
    acceptExternalTraffic: boolean;
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1Dto
     */
    externalTrafficAuthentication?: RevisionSpecV1DtoExternalTrafficAuthenticationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionSpecV1Dto
     */
    spotComputeEnabled: boolean;
    /**
     * Mount path is map key and resource ID
     * @type {{ [key: string]: SecretFileMountSpecV1Dto | undefined; }}
     * @memberof RevisionSpecV1Dto
     */
    secretFileMounts?: { [key: string]: SecretFileMountSpecV1Dto | undefined; };
    /**
     * Environment variable name is map key and resource ID
     * @type {{ [key: string]: EnvVarSpecV1Dto | undefined; }}
     * @memberof RevisionSpecV1Dto
     */
    envVars?: { [key: string]: EnvVarSpecV1Dto | undefined; };
    /**
     * 
     * @type {Array<string>}
     * @memberof RevisionSpecV1Dto
     */
    containerRunCommand?: Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionSpecV1Dto
     */
    vaultProxyEnabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RevisionSpecV1Dto
     */
    enabled: boolean;
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1Dto
     */
    containerImageTag: string;
    /**
     * 
     * @type {string}
     * @memberof RevisionSpecV1Dto
     */
    gitCommitHash?: string;
    /**
     * 
     * @type {Array<RevisionSpecV1AllOfSecretGroupRefsDto>}
     * @memberof RevisionSpecV1Dto
     */
    secretGroupRefs?: Array<RevisionSpecV1AllOfSecretGroupRefsDto>;
}


/**
 * @export
 */
export const RevisionSpecV1DtoExternalTrafficAuthenticationEnum = {
    AzureAuth: 'AZURE_AUTH',
    BasicAuth: 'BASIC_AUTH',
    NoAuth: 'NO_AUTH'
} as const;
export type RevisionSpecV1DtoExternalTrafficAuthenticationEnum = typeof RevisionSpecV1DtoExternalTrafficAuthenticationEnum[keyof typeof RevisionSpecV1DtoExternalTrafficAuthenticationEnum];


/**
 * Check if a given object implements the RevisionSpecV1Dto interface.
 */
export function instanceOfRevisionSpecV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "region" in value;
    isInstance = isInstance && "containerImageName" in value;
    isInstance = isInstance && "replicaScaling" in value;
    isInstance = isInstance && "resources" in value;
    isInstance = isInstance && "healthCheck" in value;
    isInstance = isInstance && "observability" in value;
    isInstance = isInstance && "acceptExternalTraffic" in value;
    isInstance = isInstance && "spotComputeEnabled" in value;
    isInstance = isInstance && "enabled" in value;
    isInstance = isInstance && "containerImageTag" in value;

    return isInstance;
}

export function RevisionSpecV1DtoFromJSON(json: any): RevisionSpecV1Dto {
    return RevisionSpecV1DtoFromJSONTyped(json, false);
}

export function RevisionSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RevisionSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'region': json['region'],
        'containerImageName': json['containerImageName'],
        'replicaScaling': ScalingSpecV1DtoFromJSON(json['replicaScaling']),
        'resources': RevisionTemplateSpecV1ResourcesDtoFromJSON(json['resources']),
        'healthCheck': HealthCheckSpecV1DtoFromJSON(json['healthCheck']),
        'observability': RevisionTemplateSpecV1ObservabilityDtoFromJSON(json['observability']),
        'exposedPort': !exists(json, 'exposedPort') ? undefined : json['exposedPort'],
        'acceptExternalTraffic': json['acceptExternalTraffic'],
        'externalTrafficAuthentication': !exists(json, 'externalTrafficAuthentication') ? undefined : json['externalTrafficAuthentication'],
        'spotComputeEnabled': json['spotComputeEnabled'],
        'secretFileMounts': !exists(json, 'secretFileMounts') ? undefined : (mapValues(json['secretFileMounts'], SecretFileMountSpecV1DtoFromJSON)),
        'envVars': !exists(json, 'envVars') ? undefined : (mapValues(json['envVars'], EnvVarSpecV1DtoFromJSON)),
        'containerRunCommand': !exists(json, 'containerRunCommand') ? undefined : json['containerRunCommand'],
        'vaultProxyEnabled': !exists(json, 'vaultProxyEnabled') ? undefined : json['vaultProxyEnabled'],
        'enabled': json['enabled'],
        'containerImageTag': json['containerImageTag'],
        'gitCommitHash': !exists(json, 'gitCommitHash') ? undefined : json['gitCommitHash'],
        'secretGroupRefs': !exists(json, 'secretGroupRefs') ? undefined : ((json['secretGroupRefs'] as Array<any>).map(RevisionSpecV1AllOfSecretGroupRefsDtoFromJSON)),
    };
}

export function RevisionSpecV1DtoToJSON(value?: RevisionSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'region': value.region,
        'containerImageName': value.containerImageName,
        'replicaScaling': ScalingSpecV1DtoToJSON(value.replicaScaling),
        'resources': RevisionTemplateSpecV1ResourcesDtoToJSON(value.resources),
        'healthCheck': HealthCheckSpecV1DtoToJSON(value.healthCheck),
        'observability': RevisionTemplateSpecV1ObservabilityDtoToJSON(value.observability),
        'exposedPort': value.exposedPort,
        'acceptExternalTraffic': value.acceptExternalTraffic,
        'externalTrafficAuthentication': value.externalTrafficAuthentication,
        'spotComputeEnabled': value.spotComputeEnabled,
        'secretFileMounts': value.secretFileMounts === undefined ? undefined : (mapValues(value.secretFileMounts, SecretFileMountSpecV1DtoToJSON)),
        'envVars': value.envVars === undefined ? undefined : (mapValues(value.envVars, EnvVarSpecV1DtoToJSON)),
        'containerRunCommand': value.containerRunCommand,
        'vaultProxyEnabled': value.vaultProxyEnabled,
        'enabled': value.enabled,
        'containerImageTag': value.containerImageTag,
        'gitCommitHash': value.gitCommitHash,
        'secretGroupRefs': value.secretGroupRefs === undefined ? undefined : ((value.secretGroupRefs as Array<any>).map(RevisionSpecV1AllOfSecretGroupRefsDtoToJSON)),
    };
}

