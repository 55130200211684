export const openModal = () => {
  const html = document.querySelector('html');
  const scrollbarWidth = window.innerWidth - document.body.offsetWidth;

  if (html) {
    html.style.overflow = 'hidden';
    html.style.paddingRight = `${scrollbarWidth}px`;
  }
};

export const closeModal = () => {
  const html = document.querySelector('html');

  if (html) {
    html.style.overflow = 'auto';
    html.style.paddingRight = '0';
  }
};
