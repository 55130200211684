/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecretsStorageStateV1Dto } from './SecretsStorageStateV1Dto';
import {
    SecretsStorageStateV1DtoFromJSON,
    SecretsStorageStateV1DtoFromJSONTyped,
    SecretsStorageStateV1DtoToJSON,
} from './SecretsStorageStateV1Dto';

/**
 * 
 * @export
 * @interface SecretsStorageStatusV1Dto
 */
export interface SecretsStorageStatusV1Dto {
    /**
     * 
     * @type {Date}
     * @memberof SecretsStorageStatusV1Dto
     */
    createdDate: Date;
    /**
     * 
     * @type {Date}
     * @memberof SecretsStorageStatusV1Dto
     */
    lastModifiedDate: Date;
    /**
     * 
     * @type {SecretsStorageStateV1Dto}
     * @memberof SecretsStorageStatusV1Dto
     */
    stateCode: SecretsStorageStateV1Dto;
    /**
     * Human readable description of the `stateCode`
     * @type {string}
     * @memberof SecretsStorageStatusV1Dto
     */
    stateMessage: string;
}

/**
 * Check if a given object implements the SecretsStorageStatusV1Dto interface.
 */
export function instanceOfSecretsStorageStatusV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "createdDate" in value;
    isInstance = isInstance && "lastModifiedDate" in value;
    isInstance = isInstance && "stateCode" in value;
    isInstance = isInstance && "stateMessage" in value;

    return isInstance;
}

export function SecretsStorageStatusV1DtoFromJSON(json: any): SecretsStorageStatusV1Dto {
    return SecretsStorageStatusV1DtoFromJSONTyped(json, false);
}

export function SecretsStorageStatusV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretsStorageStatusV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdDate': (new Date(json['createdDate'])),
        'lastModifiedDate': (new Date(json['lastModifiedDate'])),
        'stateCode': SecretsStorageStateV1DtoFromJSON(json['stateCode']),
        'stateMessage': json['stateMessage'],
    };
}

export function SecretsStorageStatusV1DtoToJSON(value?: SecretsStorageStatusV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdDate': (value.createdDate.toISOString()),
        'lastModifiedDate': (value.lastModifiedDate.toISOString()),
        'stateCode': SecretsStorageStateV1DtoToJSON(value.stateCode),
        'stateMessage': value.stateMessage,
    };
}

