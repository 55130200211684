/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationStatusV1Dto } from './ApplicationStatusV1Dto';
import {
    ApplicationStatusV1DtoFromJSON,
    ApplicationStatusV1DtoFromJSONTyped,
    ApplicationStatusV1DtoToJSON,
} from './ApplicationStatusV1Dto';

/**
 * 
 * @export
 * @interface ApplicationV1Dto
 */
export interface ApplicationV1Dto {
    /**
     * 
     * @type {string}
     * @memberof ApplicationV1Dto
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationV1Dto
     */
    displayName: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationV1Dto
     */
    teamId: string;
    /**
     * 
     * @type {string}
     * @memberof ApplicationV1Dto
     */
    macId?: string;
    /**
     * 
     * @type {ApplicationStatusV1Dto}
     * @memberof ApplicationV1Dto
     */
    status?: ApplicationStatusV1Dto;
}

/**
 * Check if a given object implements the ApplicationV1Dto interface.
 */
export function instanceOfApplicationV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "teamId" in value;

    return isInstance;
}

export function ApplicationV1DtoFromJSON(json: any): ApplicationV1Dto {
    return ApplicationV1DtoFromJSONTyped(json, false);
}

export function ApplicationV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplicationV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'displayName': json['displayName'],
        'teamId': json['teamId'],
        'macId': !exists(json, 'macId') ? undefined : json['macId'],
        'status': !exists(json, 'status') ? undefined : ApplicationStatusV1DtoFromJSON(json['status']),
    };
}

export function ApplicationV1DtoToJSON(value?: ApplicationV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'displayName': value.displayName,
        'teamId': value.teamId,
        'macId': value.macId,
        'status': ApplicationStatusV1DtoToJSON(value.status),
    };
}

