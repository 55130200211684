import { AccountV1Dto, TopicV1Dto } from '@@/generated/openapi';
import { TableColumn } from '@maersk-global/mds-components-core/mc-table/types';
import { McCheckbox } from '@maersk-global/mds-react-wrapper/components-core/mc-checkbox';
import { McTable } from '@maersk-global/mds-react-wrapper/components-core/mc-table';
import classes from './ChooseTopics.module.scss';
import { McInput } from '@maersk-global/mds-react-wrapper/components-core/mc-input';
import { useKafkaTopics } from '@/hooks/useKafkaTopics';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper/components-core/mc-loading-indicator';
import { getTopicUrl } from '@/helpers/urls';
import EmptyResult from '@/components/EmptyResult/EmptyResult';

const convertTopicsToTableData = (data: TopicV1Dto[]) => {
  return data.map((topic) => ({
    id: topic.id,
    name: topic.name,
  }));
};

const columns: Array<TableColumn> = [
  {
    id: 'name',
    label: 'Topic Name',
    width: '210px',
  },
  {
    id: 'id',
    label: 'Topic ID',
    width: '210px',
  },
  {
    id: 'actions',
    label: '',
    align: 'right',
    width: '150px',
    sortDisabled: true,
    noWrap: true,
  },
];

export const ChooseTopics = () => {
  const params = useParams();
  const { data: topics, isLoading: topicsLoading } = useKafkaTopics({
    key: ['kafka-topics-owned', params?.app, params?.env, true, 'ready'],
    args: [params?.app ?? '', params?.env ?? '', true, undefined, true],
  });
  const { data: externalTopics, isLoading: externalTopicsLoading } =
    useKafkaTopics({
      key: ['kafka-topics-shared', params?.app, params?.env, false, 'ready'],
      args: [params?.app ?? '', params?.env ?? '', false, undefined, true],
    });
  const { setValue, watch } = useFormContext<AccountV1Dto>();
  const [input, setInput] = useState('');
  const { app } = useParams();

  // Watch current form values
  const consumeTopics = watch('consumeTopics') || [];
  const produceTopics = watch('produceTopics') || [];

  const handleInputChange = (e: InputEvent) => {
    const target = e.target as HTMLInputElement;
    setInput(target.value);
  };

  const filteredTopics = [...(topics ?? []), ...(externalTopics ?? [])].filter(
    (topic) =>
      topic.name.toLowerCase().includes(input.toLowerCase()) ||
      topic.id?.toLowerCase().includes(input.toLowerCase()),
  );

  const handleCheckboxChange = (topic: TopicV1Dto, type: 'read' | 'write') => {
    const topicsList = type === 'read' ? consumeTopics : produceTopics;
    const formField = type === 'read' ? 'consumeTopics' : 'produceTopics';

    if (!topic.id) return;
    if (topicsList.some(({ resourceId }) => resourceId === topic.id)) {
      setValue(
        formField,
        topicsList.filter(({ resourceId }) => resourceId !== topic.id),
      );
    } else {
      setValue(formField, [
        ...topicsList,
        {
          resourceId: topic.id,
          applicationCode: app ?? '',
          resourceType: 'TOPIC',
        },
      ]);
    }
  };

  return (
    <div className={classes.container}>
      <label>Choose Topics</label>

      <McTable
        data={convertTopicsToTableData(filteredTopics)}
        sortdefaultcolumnid="name"
        columns={columns}
        stateslotheight="200px"
      >
        <McInput
          slot="actions_header"
          fit="small"
          placeholder="Filter by name"
          hiddenlabel
          icon="magnifying-glass"
          input={handleInputChange}
        />
        {(topicsLoading || externalTopicsLoading) && (
          <McLoadingIndicator label="Loading topics..." slot="state" />
        )}
        {!filteredTopics?.length &&
          !topicsLoading &&
          !externalTopicsLoading && (
            <div slot="state">
              <EmptyResult>No topics found</EmptyResult>
            </div>
          )}
        {filteredTopics?.map((topic) => (
          <>
            <div key={`${topic.id}_id`} slot={`${topic.id}_id`}>
              <Link
                target="_blank"
                to={getTopicUrl({
                  app: params.app,
                  env: params.env,
                  topic: topic.id,
                })}
              >
                {topic.id}
              </Link>
            </div>
            <div
              key={topic.id}
              slot={`${topic.id}_actions`}
              className={classes.actions}
              data-testid={`${topic.id}_actions`}
            >
              <McCheckbox
                label="read"
                checked={consumeTopics.some(
                  ({ resourceId }) => resourceId === topic.id,
                )}
                change={() => handleCheckboxChange(topic, 'read')}
              />
              <McCheckbox
                label="write"
                checked={produceTopics.some(
                  ({ resourceId }) => resourceId === topic.id,
                )}
                change={() => handleCheckboxChange(topic, 'write')}
              />
            </div>
          </>
        ))}
      </McTable>
    </div>
  );
};
