import AppPageHeader from '@/components/AppPageHeader/AppPageHeader';
import PageBody from '@/components/PageBody/PageBody';
import { SchemaForm } from '@/components/EventsProcessing/SchemaForm/SchemaForm';
import classes from './EventsProcessingCreateSchemaPage.module.scss';
import { PipelineV1Dto, SchemaV1Dto } from '@@/generated/openapi';
import useSWRMutation from 'swr/mutation';
import { waitForPipeline } from '@/helpers/waitForPipeline';
import { eventsProcessingPage } from '@text';
import { getSchemaUrl } from '@/helpers/urls';

const extractSchemaIdFromPipelineStep = (pipeline: PipelineV1Dto) => {
  const schemaId = pipeline.status?.steps?.find(
    (step) => step.code === 'WaitForSchemaCreation',
  )?.message?.context?.schemaId;
  return schemaId;
};

export const EventsProcessingCreateSchemaPage = () => {
  const client = useApiClient();
  const params = useParams();
  const navigate = useNavigate();
  const { trigger } = useSWRMutation(
    ['schema-create'],
    async (_key: [string], { arg: data }: { arg: SchemaV1Dto }) =>
      await waitForPipeline<SchemaV1Dto>(
        () => client.createSchemaV1(data, params.app ?? '', params.env ?? ''),
        (id) => client.getPipeline(id),
        (pipeline) =>
          client.getSchemaV1(
            params.app ?? '',
            params.env ?? '',
            extractSchemaIdFromPipelineStep(pipeline as PipelineV1Dto) ?? '',
          ),
      ),
  );

  const onSubmit = async (data: SchemaV1Dto) => {
    try {
      const response = await trigger(data);
      if (response?.id) {
        navigate(
          getSchemaUrl({
            schema: response.id,
            app: params.app ?? '',
            env: params.env ?? '',
          }),
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <AppPageHeader />
      <PageBody
        className={classes.pageBody}
        title={eventsProcessingPage.createSchema.title}
        padding
      >
        <SchemaForm onSubmit={onSubmit} />
      </PageBody>
    </>
  );
};
