import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { McTooltip } from '@maersk-global/mds-react-wrapper/components-core/mc-tooltip';
import ConfirmationModal from '@/components/ConfirmationModal/ConfirmationModal';
import useSwrMutation from 'swr/mutation';
import { withErrorProvider } from '@/hoc/withErrorProvider';
import { useUserBelongsToAppTeam } from '@/hooks/useUserBelongsToAppTeam';
import { getAccountsUrl, getSchemasUrl, getTopicsUrl } from '@/helpers/urls';
import { useNavigate, useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import useApiClient from '@/hooks/useApiClient';
import { useState } from 'react';
import { useToast } from '@/providers/ToastProvider';

interface DeleteResourceProps {
  resourceType: 'schema' | 'account' | 'topic';
  resourceId: string;
  mutateKey: string[];
  disabled?: boolean;
  disabledTooltip?: string;
}

const DeleteResource = ({
  resourceType,
  resourceId,
  mutateKey,
  disabled,
  disabledTooltip,
}: DeleteResourceProps) => {
  const params = useParams();
  const client = useApiClient();
  const navigate = useNavigate();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const userPartOfTeam = useUserBelongsToAppTeam();
  const { mutate } = useSWRConfig();

  const deleteResource = async () => {
    switch (resourceType) {
      case 'schema':
        return await client.deleteSchemaV1(
          params?.app ?? '',
          params?.env ?? '',
          resourceId,
        );
      case 'account':
        return await client.deleteAccountV1(
          params?.app ?? '',
          params?.env ?? '',
          resourceId,
        );
      case 'topic':
        return await client.deleteTopicV1(
          params?.app ?? '',
          params?.env ?? '',
          resourceId,
        );
    }
  };

  const { trigger } = useSwrMutation(
    [`${resourceType}-delete`, resourceId, params.app, params.env],
    deleteResource,
  );

  const { showToast } = useToast();

  const handleDelete = async () => {
    await trigger();
    await mutate(mutateKey);
    switch (resourceType) {
      case 'schema':
        navigate(getSchemasUrl({ app: params.app, env: params.env }));
        showToast({
          message: 'Schema deleted successfully',
          appearance: 'success',
        });
        break;
      case 'account':
        navigate(getAccountsUrl({ app: params.app, env: params.env }));
        showToast({
          message: 'Account deleted successfully',
          appearance: 'success',
        });
        break;
      case 'topic':
        navigate(getTopicsUrl({ app: params.app, env: params.env }));
        showToast({
          message: 'Topic deleted successfully',
          appearance: 'success',
        });
        break;
    }
  };

  if (!userPartOfTeam) {
    return null;
  }

  const deleteButton = (
    <McButton
      icon={'trash'}
      variant={'plain'}
      appearance={'neutral'}
      label="Delete"
      click={() => setShowDeleteModal(true)}
      disabled={disabled}
      slot={'trigger'}
      data-testid={`delete-${resourceType}`}
    />
  );

  return (
    <div>
      <ConfirmationModal
        onClose={() => setShowDeleteModal(false)}
        onSubmit={handleDelete}
        title={`Delete ${
          resourceType.charAt(0).toUpperCase() + resourceType.slice(1)
        }`}
        text={`Are you sure you want to delete this ${resourceType}? This action cannot be undone.`}
        open={showDeleteModal}
      />

      {disabled && disabledTooltip ? (
        <McTooltip position={'top-right'}>
          {deleteButton}
          <div>{disabledTooltip}</div>
        </McTooltip>
      ) : (
        deleteButton
      )}
    </div>
  );
};

export default withErrorProvider(DeleteResource);
