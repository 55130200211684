import { useFormContext } from 'react-hook-form';
import CodeEditor from '../CodeEditor/CodeEditor';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';

interface CodeInputProps {
  name: string;
  readonly?: boolean;
  text?: {
    label?: string;
    placeholder?: string;
    hint?: string;
  };
  language?: string;
  formatter?: (value: string) => string;
  showFormatter?: boolean;
  showCopy?: boolean;
}

function CodeInput({
  name,
  text,
  language = 'json',
  readonly,
  formatter,
  showFormatter = false,
  showCopy = false,
}: CodeInputProps) {
  const { getValues, setValue } = useFormContext();
  const value = getValues(name) as string;
  const format = useCallback(() => {
    if (formatter) {
      setValue(name, formatter(value));
    }
  }, [formatter, setValue, name, value]);
  const onChange = useCallback(
    (code: string) => {
      setValue(name, code);
    },
    [setValue, name],
  );
  return (
    <CodeEditor
      value={value}
      onChange={onChange}
      actions={
        <>
          {showFormatter && (
            <McButton
              appearance="primary"
              variant="plain"
              hiddenlabel
              icon="pencil"
              onClick={format}
            >
              Format
            </McButton>
          )}
          {showCopy && (
            <McButton
              appearance="primary"
              variant="plain"
              hiddenlabel
              icon="copy"
              onClick={() => navigator.clipboard.writeText(value)}
            >
              Copy
            </McButton>
          )}
        </>
      }
      text={text}
      language={language}
      readonly={readonly}
    />
  );
}

export default CodeInput;
