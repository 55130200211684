/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Technical Team Service (MTTS)
 * Maersk Technical Team Service API
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  ApplicationIdDto,
  ApplicationPageDto,
  ApplicationV1Dto,
  IdentityPageDto,
  IdentityV1Dto,
  PatchMacIdForApplicationRequestDto,
  PipelineStatusV1Dto,
  ProblemDetailV1Dto,
  TeamPageDto,
  TeamV1Dto,
} from '../models/index';
import {
    ApplicationIdDtoFromJSON,
    ApplicationIdDtoToJSON,
    ApplicationPageDtoFromJSON,
    ApplicationPageDtoToJSON,
    ApplicationV1DtoFromJSON,
    ApplicationV1DtoToJSON,
    IdentityPageDtoFromJSON,
    IdentityPageDtoToJSON,
    IdentityV1DtoFromJSON,
    IdentityV1DtoToJSON,
    PatchMacIdForApplicationRequestDtoFromJSON,
    PatchMacIdForApplicationRequestDtoToJSON,
    PipelineStatusV1DtoFromJSON,
    PipelineStatusV1DtoToJSON,
    ProblemDetailV1DtoFromJSON,
    ProblemDetailV1DtoToJSON,
    TeamPageDtoFromJSON,
    TeamPageDtoToJSON,
    TeamV1DtoFromJSON,
    TeamV1DtoToJSON,
} from '../models/index';

export interface CreateApplicationRequest {
    applicationV1Dto: ApplicationV1Dto;
    macAppId?: string;
    macPlatformId?: string;
}

export interface CreateApplicationWithoutMacRequest {
    applicationV1Dto: ApplicationV1Dto;
}

export interface CreateTeamRequest {
    teamV1Dto: TeamV1Dto;
}

export interface CreateTeamAsyncRequest {
    teamV1Dto: TeamV1Dto;
}

export interface DeleteApplicationRequest {
    applicationId: string;
}

export interface DeleteTeamRequest {
    teamId: string;
}

export interface DeleteTeamAsyncRequest {
    teamId: string;
}

export interface FetchPipelineStatusRequest {
    pipelineId: string;
    runId?: string;
}

export interface GetApplicationRequest {
    applicationId: string;
}

export interface GetApplicationIdByAdmiralProductCodeRequest {
    productCode: string;
}

export interface GetApplicationsRequest {
    teamId?: string;
    identityId?: string;
    macAppId?: string;
    application?: string;
    page?: number;
    size?: number;
}

export interface GetApplicationsV1Request {
    teamId?: string;
    identityId?: string;
    page?: number;
    size?: number;
}

export interface GetIdentitiesRequest {
    teamId?: string;
    query?: string;
    page?: number;
    size?: number;
}

export interface GetIdentityRequest {
    identityId: string;
}

export interface GetTeamRequest {
    teamId: string;
}

export interface GetTeamV1Request {
    teamId: string;
}

export interface GetTeamsRequest {
    page?: number;
    size?: number;
    identityId?: string;
    applicationId?: string;
    team?: string;
}

export interface GetTeamsV1Request {
    page?: number;
    size?: number;
    identityId?: string;
    applicationId?: string;
}

export interface PatchMacIdForApplicationRequest {
    patchMacIdForApplicationRequestDto: PatchMacIdForApplicationRequestDto;
    applicationId: string;
}

export interface UpdateApplicationRequest {
    applicationV1Dto: ApplicationV1Dto;
    applicationId: string;
}

export interface UpdateTeamRequest {
    teamV1Dto: TeamV1Dto;
    teamId: string;
}

export interface UpdateTeamAsyncRequest {
    teamV1Dto: TeamV1Dto;
    teamId: string;
}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI {

    /**
     * Create application
     */
    async createApplicationRaw(requestParameters: CreateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplication.');
        }

        const queryParameters: any = {};

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.macPlatformId !== undefined) {
            queryParameters['macPlatformId'] = requestParameters.macPlatformId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application
     */
    async createApplication(applicationV1Dto: ApplicationV1Dto, macAppId?: string, macPlatformId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.createApplicationRaw({ applicationV1Dto: applicationV1Dto, macAppId: macAppId, macPlatformId: macPlatformId }, initOverrides);
        return await response.value();
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationWithoutMacRaw(requestParameters: CreateApplicationWithoutMacRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling createApplicationWithoutMac.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Create application
     * @deprecated
     */
    async createApplicationWithoutMac(applicationV1Dto: ApplicationV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.createApplicationWithoutMacRaw({ applicationV1Dto: applicationV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create team
     * @deprecated
     */
    async createTeamRaw(requestParameters: CreateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamV1Dto>> {
        if (requestParameters.teamV1Dto === null || requestParameters.teamV1Dto === undefined) {
            throw new runtime.RequiredError('teamV1Dto','Required parameter requestParameters.teamV1Dto was null or undefined when calling createTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v1`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamV1DtoToJSON(requestParameters.teamV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamV1DtoFromJSON(jsonValue));
    }

    /**
     * Create team
     * @deprecated
     */
    async createTeam(teamV1Dto: TeamV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamV1Dto> {
        const response = await this.createTeamRaw({ teamV1Dto: teamV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Create team
     */
    async createTeamAsyncRaw(requestParameters: CreateTeamAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.teamV1Dto === null || requestParameters.teamV1Dto === undefined) {
            throw new runtime.RequiredError('teamV1Dto','Required parameter requestParameters.teamV1Dto was null or undefined when calling createTeamAsync.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v2`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: TeamV1DtoToJSON(requestParameters.teamV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Create team
     */
    async createTeamAsync(teamV1Dto: TeamV1Dto, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.createTeamAsyncRaw({ teamV1Dto: teamV1Dto }, initOverrides);
        return await response.value();
    }

    /**
     * Delete application
     */
    async deleteApplicationRaw(requestParameters: DeleteApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling deleteApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{applicationId}`.replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete application
     */
    async deleteApplication(applicationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteApplicationRaw({ applicationId: applicationId }, initOverrides);
    }

    /**
     * Delete team
     * @deprecated
     */
    async deleteTeamRaw(requestParameters: DeleteTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v1/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete team
     * @deprecated
     */
    async deleteTeam(teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTeamRaw({ teamId: teamId }, initOverrides);
    }

    /**
     * Delete team
     */
    async deleteTeamAsyncRaw(requestParameters: DeleteTeamAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling deleteTeamAsync.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v2/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Delete team
     */
    async deleteTeamAsync(teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.deleteTeamAsyncRaw({ teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     * Fetch pipeline status
     */
    async fetchPipelineStatusRaw(requestParameters: FetchPipelineStatusRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.pipelineId === null || requestParameters.pipelineId === undefined) {
            throw new runtime.RequiredError('pipelineId','Required parameter requestParameters.pipelineId was null or undefined when calling fetchPipelineStatus.');
        }

        const queryParameters: any = {};

        if (requestParameters.runId !== undefined) {
            queryParameters['runId'] = requestParameters.runId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/pipelines/v1/{pipelineId}`.replace(`{${"pipelineId"}}`, encodeURIComponent(String(requestParameters.pipelineId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Fetch pipeline status
     */
    async fetchPipelineStatus(pipelineId: string, runId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.fetchPipelineStatusRaw({ pipelineId: pipelineId, runId: runId }, initOverrides);
        return await response.value();
    }

    /**
     * Get application
     */
    async getApplicationRaw(requestParameters: GetApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationV1Dto>> {
        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling getApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{applicationId}`.replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationV1DtoFromJSON(jsonValue));
    }

    /**
     * Get application
     */
    async getApplication(applicationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationV1Dto> {
        const response = await this.getApplicationRaw({ applicationId: applicationId }, initOverrides);
        return await response.value();
    }

    /**
     * Get application id by admiral product code
     * @deprecated
     */
    async getApplicationIdByAdmiralProductCodeRaw(requestParameters: GetApplicationIdByAdmiralProductCodeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationIdDto>> {
        if (requestParameters.productCode === null || requestParameters.productCode === undefined) {
            throw new runtime.RequiredError('productCode','Required parameter requestParameters.productCode was null or undefined when calling getApplicationIdByAdmiralProductCode.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/admiral/products/{productCode}`.replace(`{${"productCode"}}`, encodeURIComponent(String(requestParameters.productCode))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationIdDtoFromJSON(jsonValue));
    }

    /**
     * Get application id by admiral product code
     * @deprecated
     */
    async getApplicationIdByAdmiralProductCode(productCode: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationIdDto> {
        const response = await this.getApplicationIdByAdmiralProductCodeRaw({ productCode: productCode }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     */
    async getApplicationsRaw(requestParameters: GetApplicationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        if (requestParameters.identityId !== undefined) {
            queryParameters['identityId'] = requestParameters.identityId;
        }

        if (requestParameters.macAppId !== undefined) {
            queryParameters['macAppId'] = requestParameters.macAppId;
        }

        if (requestParameters.application !== undefined) {
            queryParameters['application'] = requestParameters.application;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPageDtoFromJSON(jsonValue));
    }

    /**
     * Get applications
     */
    async getApplications(teamId?: string, identityId?: string, macAppId?: string, application?: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationPageDto> {
        const response = await this.getApplicationsRaw({ teamId: teamId, identityId: identityId, macAppId: macAppId, application: application, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get applications
     * @deprecated
     */
    async getApplicationsV1Raw(requestParameters: GetApplicationsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ApplicationPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        if (requestParameters.identityId !== undefined) {
            queryParameters['identityId'] = requestParameters.identityId;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApplicationPageDtoFromJSON(jsonValue));
    }

    /**
     * Get applications
     * @deprecated
     */
    async getApplicationsV1(teamId?: string, identityId?: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ApplicationPageDto> {
        const response = await this.getApplicationsV1Raw({ teamId: teamId, identityId: identityId, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get current identities info. One of two parameters: `searchedTeamId`, `identityNameQuery` is required.
     */
    async getIdentitiesRaw(requestParameters: GetIdentitiesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.teamId !== undefined) {
            queryParameters['teamId'] = requestParameters.teamId;
        }

        if (requestParameters.query !== undefined) {
            queryParameters['query'] = requestParameters.query;
        }

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/identities/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityPageDtoFromJSON(jsonValue));
    }

    /**
     * Get current identities info. One of two parameters: `searchedTeamId`, `identityNameQuery` is required.
     */
    async getIdentities(teamId?: string, query?: string, page?: number, size?: number, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityPageDto> {
        const response = await this.getIdentitiesRaw({ teamId: teamId, query: query, page: page, size: size }, initOverrides);
        return await response.value();
    }

    /**
     * Get identity
     */
    async getIdentityRaw(requestParameters: GetIdentityRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<IdentityV1Dto>> {
        if (requestParameters.identityId === null || requestParameters.identityId === undefined) {
            throw new runtime.RequiredError('identityId','Required parameter requestParameters.identityId was null or undefined when calling getIdentity.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/identities/v1/{identityId}`.replace(`{${"identityId"}}`, encodeURIComponent(String(requestParameters.identityId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IdentityV1DtoFromJSON(jsonValue));
    }

    /**
     * Get identity
     */
    async getIdentity(identityId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<IdentityV1Dto> {
        const response = await this.getIdentityRaw({ identityId: identityId }, initOverrides);
        return await response.value();
    }

    /**
     * Get team
     */
    async getTeamRaw(requestParameters: GetTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamV1Dto>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v2/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamV1DtoFromJSON(jsonValue));
    }

    /**
     * Get team
     */
    async getTeam(teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamV1Dto> {
        const response = await this.getTeamRaw({ teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     * Get team
     * @deprecated
     */
    async getTeamV1Raw(requestParameters: GetTeamV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamV1Dto>> {
        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling getTeamV1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v1/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamV1DtoFromJSON(jsonValue));
    }

    /**
     * Get team
     * @deprecated
     */
    async getTeamV1(teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamV1Dto> {
        const response = await this.getTeamV1Raw({ teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     * Get teams (params identityId and applicationId are mutually exclusive)
     */
    async getTeamsRaw(requestParameters: GetTeamsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.identityId !== undefined) {
            queryParameters['identityId'] = requestParameters.identityId;
        }

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        if (requestParameters.team !== undefined) {
            queryParameters['team'] = requestParameters.team;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamPageDtoFromJSON(jsonValue));
    }

    /**
     * Get teams (params identityId and applicationId are mutually exclusive)
     */
    async getTeams(page?: number, size?: number, identityId?: string, applicationId?: string, team?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamPageDto> {
        const response = await this.getTeamsRaw({ page: page, size: size, identityId: identityId, applicationId: applicationId, team: team }, initOverrides);
        return await response.value();
    }

    /**
     * Get teams (params identityId and applicationId are mutually exclusive)
     * @deprecated
     */
    async getTeamsV1Raw(requestParameters: GetTeamsV1Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamPageDto>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.identityId !== undefined) {
            queryParameters['identityId'] = requestParameters.identityId;
        }

        if (requestParameters.applicationId !== undefined) {
            queryParameters['applicationId'] = requestParameters.applicationId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v1`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamPageDtoFromJSON(jsonValue));
    }

    /**
     * Get teams (params identityId and applicationId are mutually exclusive)
     * @deprecated
     */
    async getTeamsV1(page?: number, size?: number, identityId?: string, applicationId?: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamPageDto> {
        const response = await this.getTeamsV1Raw({ page: page, size: size, identityId: identityId, applicationId: applicationId }, initOverrides);
        return await response.value();
    }

    /**
     * Update macId field in application
     */
    async patchMacIdForApplicationRaw(requestParameters: PatchMacIdForApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.patchMacIdForApplicationRequestDto === null || requestParameters.patchMacIdForApplicationRequestDto === undefined) {
            throw new runtime.RequiredError('patchMacIdForApplicationRequestDto','Required parameter requestParameters.patchMacIdForApplicationRequestDto was null or undefined when calling patchMacIdForApplication.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling patchMacIdForApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/mac-id-patch+json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{applicationId}`.replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: PatchMacIdForApplicationRequestDtoToJSON(requestParameters.patchMacIdForApplicationRequestDto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update macId field in application
     */
    async patchMacIdForApplication(patchMacIdForApplicationRequestDto: PatchMacIdForApplicationRequestDto, applicationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.patchMacIdForApplicationRaw({ patchMacIdForApplicationRequestDto: patchMacIdForApplicationRequestDto, applicationId: applicationId }, initOverrides);
    }

    /**
     * Update application (macId cannot be updated through PUT operation). Since we consider this action as heavily impactful we decided to create dedicated PATCH endpoint
     */
    async updateApplicationRaw(requestParameters: UpdateApplicationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.applicationV1Dto === null || requestParameters.applicationV1Dto === undefined) {
            throw new runtime.RequiredError('applicationV1Dto','Required parameter requestParameters.applicationV1Dto was null or undefined when calling updateApplication.');
        }

        if (requestParameters.applicationId === null || requestParameters.applicationId === undefined) {
            throw new runtime.RequiredError('applicationId','Required parameter requestParameters.applicationId was null or undefined when calling updateApplication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/applications/v1/{applicationId}`.replace(`{${"applicationId"}}`, encodeURIComponent(String(requestParameters.applicationId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ApplicationV1DtoToJSON(requestParameters.applicationV1Dto),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update application (macId cannot be updated through PUT operation). Since we consider this action as heavily impactful we decided to create dedicated PATCH endpoint
     */
    async updateApplication(applicationV1Dto: ApplicationV1Dto, applicationId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateApplicationRaw({ applicationV1Dto: applicationV1Dto, applicationId: applicationId }, initOverrides);
    }

    /**
     * Update team
     * @deprecated
     */
    async updateTeamRaw(requestParameters: UpdateTeamRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<TeamV1Dto>> {
        if (requestParameters.teamV1Dto === null || requestParameters.teamV1Dto === undefined) {
            throw new runtime.RequiredError('teamV1Dto','Required parameter requestParameters.teamV1Dto was null or undefined when calling updateTeam.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateTeam.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v1/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamV1DtoToJSON(requestParameters.teamV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TeamV1DtoFromJSON(jsonValue));
    }

    /**
     * Update team
     * @deprecated
     */
    async updateTeam(teamV1Dto: TeamV1Dto, teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<TeamV1Dto> {
        const response = await this.updateTeamRaw({ teamV1Dto: teamV1Dto, teamId: teamId }, initOverrides);
        return await response.value();
    }

    /**
     * Update team
     */
    async updateTeamAsyncRaw(requestParameters: UpdateTeamAsyncRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<PipelineStatusV1Dto>> {
        if (requestParameters.teamV1Dto === null || requestParameters.teamV1Dto === undefined) {
            throw new runtime.RequiredError('teamV1Dto','Required parameter requestParameters.teamV1Dto was null or undefined when calling updateTeamAsync.');
        }

        if (requestParameters.teamId === null || requestParameters.teamId === undefined) {
            throw new runtime.RequiredError('teamId','Required parameter requestParameters.teamId was null or undefined when calling updateTeamAsync.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("jwt", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/teams/v2/{teamId}`.replace(`{${"teamId"}}`, encodeURIComponent(String(requestParameters.teamId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: TeamV1DtoToJSON(requestParameters.teamV1Dto),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PipelineStatusV1DtoFromJSON(jsonValue));
    }

    /**
     * Update team
     */
    async updateTeamAsync(teamV1Dto: TeamV1Dto, teamId: string, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<PipelineStatusV1Dto> {
        const response = await this.updateTeamAsyncRaw({ teamV1Dto: teamV1Dto, teamId: teamId }, initOverrides);
        return await response.value();
    }

}
