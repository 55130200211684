/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ConsumerGroupV1Dto } from './ConsumerGroupV1Dto';
import {
    ConsumerGroupV1DtoFromJSON,
    ConsumerGroupV1DtoFromJSONTyped,
    ConsumerGroupV1DtoToJSON,
} from './ConsumerGroupV1Dto';
import type { EnvironmentClassV1Dto } from './EnvironmentClassV1Dto';
import {
    EnvironmentClassV1DtoFromJSON,
    EnvironmentClassV1DtoFromJSONTyped,
    EnvironmentClassV1DtoToJSON,
} from './EnvironmentClassV1Dto';
import type { PipelineV1Dto } from './PipelineV1Dto';
import {
    PipelineV1DtoFromJSON,
    PipelineV1DtoFromJSONTyped,
    PipelineV1DtoToJSON,
} from './PipelineV1Dto';
import type { ResourceRefV1Dto } from './ResourceRefV1Dto';
import {
    ResourceRefV1DtoFromJSON,
    ResourceRefV1DtoFromJSONTyped,
    ResourceRefV1DtoToJSON,
} from './ResourceRefV1Dto';
import type { TagV1Dto } from './TagV1Dto';
import {
    TagV1DtoFromJSON,
    TagV1DtoFromJSONTyped,
    TagV1DtoToJSON,
} from './TagV1Dto';

/**
 * 
 * @export
 * @interface AccountV1Dto
 */
export interface AccountV1Dto {
    /**
     * Retina resource ID
     * 
     * @type {string}
     * @memberof AccountV1Dto
     */
    readonly id?: string;
    /**
     * The human-readable name of the resource.
     * @type {string}
     * @memberof AccountV1Dto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof AccountV1Dto
     */
    description?: string;
    /**
     * Code of application which owns this resource.
     * @type {string}
     * @memberof AccountV1Dto
     */
    readonly applicationCode: string;
    /**
     * revisionId is a unique identifier of a resource revision.
     * 
     * @type {string}
     * @memberof AccountV1Dto
     */
    revisionId?: string;
    /**
     * A list of tags identifying the set of credentials' usage context. Provides additional context to the credentials and enhances searchability.
     * 
     * @type {Array<TagV1Dto>}
     * @memberof AccountV1Dto
     */
    tags?: Array<TagV1Dto>;
    /**
     * 
     * @type {EnvironmentClassV1Dto}
     * @memberof AccountV1Dto
     */
    environmentClass?: EnvironmentClassV1Dto;
    /**
     * 
     * @type {PipelineV1Dto}
     * @memberof AccountV1Dto
     */
    pipeline?: PipelineV1Dto;
    /**
     * 
     * @type {Array<ConsumerGroupV1Dto>}
     * @memberof AccountV1Dto
     */
    consumerGroups?: Array<ConsumerGroupV1Dto>;
    /**
     * 
     * @type {Array<ResourceRefV1Dto>}
     * @memberof AccountV1Dto
     */
    produceTopics?: Array<ResourceRefV1Dto>;
    /**
     * 
     * @type {Array<ResourceRefV1Dto>}
     * @memberof AccountV1Dto
     */
    consumeTopics?: Array<ResourceRefV1Dto>;
    /**
     * The path to the secret in the MSS vault. The path is read-only and is set by the orchestrator.
     * You can find the password and other configuration details like bootstrap servers list there.
     * 
     * @type {string}
     * @memberof AccountV1Dto
     */
    readonly secretPath: string;
}

/**
 * Check if a given object implements the AccountV1Dto interface.
 */
export function instanceOfAccountV1Dto(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "applicationCode" in value;
    isInstance = isInstance && "secretPath" in value;

    return isInstance;
}

export function AccountV1DtoFromJSON(json: any): AccountV1Dto {
    return AccountV1DtoFromJSONTyped(json, false);
}

export function AccountV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'applicationCode': json['applicationCode'],
        'revisionId': !exists(json, 'revisionId') ? undefined : json['revisionId'],
        'tags': !exists(json, 'tags') ? undefined : ((json['tags'] as Array<any>).map(TagV1DtoFromJSON)),
        'environmentClass': !exists(json, 'environmentClass') ? undefined : EnvironmentClassV1DtoFromJSON(json['environmentClass']),
        'pipeline': !exists(json, 'pipeline') ? undefined : PipelineV1DtoFromJSON(json['pipeline']),
        'consumerGroups': !exists(json, 'consumerGroups') ? undefined : ((json['consumerGroups'] as Array<any>).map(ConsumerGroupV1DtoFromJSON)),
        'produceTopics': !exists(json, 'produceTopics') ? undefined : ((json['produceTopics'] as Array<any>).map(ResourceRefV1DtoFromJSON)),
        'consumeTopics': !exists(json, 'consumeTopics') ? undefined : ((json['consumeTopics'] as Array<any>).map(ResourceRefV1DtoFromJSON)),
        'secretPath': json['secretPath'],
    };
}

export function AccountV1DtoToJSON(value?: AccountV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'description': value.description,
        'revisionId': value.revisionId,
        'tags': value.tags === undefined ? undefined : ((value.tags as Array<any>).map(TagV1DtoToJSON)),
        'environmentClass': EnvironmentClassV1DtoToJSON(value.environmentClass),
        'pipeline': PipelineV1DtoToJSON(value.pipeline),
        'consumerGroups': value.consumerGroups === undefined ? undefined : ((value.consumerGroups as Array<any>).map(ConsumerGroupV1DtoToJSON)),
        'produceTopics': value.produceTopics === undefined ? undefined : ((value.produceTopics as Array<any>).map(ResourceRefV1DtoToJSON)),
        'consumeTopics': value.consumeTopics === undefined ? undefined : ((value.consumeTopics as Array<any>).map(ResourceRefV1DtoToJSON)),
    };
}

