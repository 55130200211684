import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AccountV1Dto } from '@@/generated/openapi';
import Input from '@/components/Input/Input';
import FormWrapper from '@/components/FormWrapper/FormWrapper';
import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import { ChooseTopics } from '../ChooseTopics/ChooseTopics';
import { FormInputGroup } from '@/components/FormInputGroup/FormInputGroup';
import { eventsProcessingPage } from '@text';
import { lowercaseOnly } from '@/helpers/regex';
import Textarea from '@/components/Textarea/Textarea';

const schema = yup.object({
  name: yup
    .string()
    .matches(lowercaseOnly, 'Account name can only contain lowercase letters')
    .required('Please provide an account name'),
  description: yup.string(),
});

const defaultAccount: Partial<AccountV1Dto> = {
  name: '',
  applicationCode: '',
  environmentClass: 'NONPROD',
  description: '',
  produceTopics: [
    {
      resourceType: 'TOPIC',
      applicationCode: 'keepmdptest',
      resourceId: 'keepmdptest.topic.sandbox.testtopic',
    },
  ],
  consumeTopics: [
    {
      resourceType: 'TOPIC',
      applicationCode: 'keepmdptest',
      resourceId: 'keepmdptest.topic.sandbox.testtopic',
    },
  ],
};

type AccountFormProps = {
  initialData?: Partial<AccountV1Dto>;
  onSubmit: (data: AccountV1Dto) => Promise<void>;
  onCancel?: () => void;
  isLoading?: boolean;
  mode?: 'create' | 'edit';
};

export const AccountForm = ({
  initialData = defaultAccount,
  onSubmit,
  onCancel,
  isLoading,
  mode = 'create',
}: AccountFormProps) => {
  const { app } = useParams();
  const userBelongsToAppTeam = useUserBelongsToAppTeam();
  const form = useForm<AccountV1Dto>({
    // @ts-expect-error schema is not properly typed
    resolver: yupResolver(schema),
    defaultValues: {
      applicationCode: app ?? '',
      ...initialData,
      produceTopics: initialData.produceTopics ?? [],
      consumeTopics: initialData.consumeTopics ?? [],
    },
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    await onSubmit(data);
  });

  return (
    <FormWrapper form={form} schema={schema}>
      <form onSubmit={handleSubmit}>
        <FormInputGroup gap="large">
          <FormInputGroup width="normal">
            {mode === 'create' && (
              <Input
                name="name"
                text={eventsProcessingPage.accountForm.name}
                disabled={isLoading}
                data-testid="account-name"
              />
            )}

            <Textarea
              name="description"
              rows={3}
              text={eventsProcessingPage.accountForm.description}
              data-testid="description"
              disabled={isLoading}
            />
          </FormInputGroup>

          <ChooseTopics />

          <div className="form-actions">
            {onCancel && (
              <McButton
                type="button"
                variant="outlined"
                appearance="primary"
                onClick={onCancel}
                loading={isLoading}
              >
                Cancel
              </McButton>
            )}
            <McButton
              loading={isLoading}
              type="submit"
              disabled={!userBelongsToAppTeam}
            >
              {mode === 'create'
                ? eventsProcessingPage.accountForm.submit.create
                : eventsProcessingPage.accountForm.submit.edit}
            </McButton>
          </div>
        </FormInputGroup>
      </form>
    </FormWrapper>
  );
};
