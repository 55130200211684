import { ComponentType } from 'react';
import ErrorProvider from '@/providers/Error';

export function withErrorProvider<T extends object>(
  Component: ComponentType<T>,
) {
  const displayName = Component.displayName;

  const ComponentWithErrorProvider = (props: T) => {
    return (
      <ErrorProvider>
        <Component {...props} />
      </ErrorProvider>
    );
  };

  ComponentWithErrorProvider.displayName = displayName;

  return ComponentWithErrorProvider;
}
