export const useUserBelongsToAppTeam = () => {
  const user = useUser();
  const { data: app } = useApp();
  const { data } = useTeam({
    args: [app?.spec.teamRef.name || ''],
    pause: !app,
  });

  if (!app?.spec.teamRef) {
    return false;
  }

  return (
    [...(data?.memberIds || []), ...(data?.ownerIds || [])]?.some(
      (member) => member === user.id,
    ) || false
  );
};
