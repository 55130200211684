import { useFormContext } from 'react-hook-form';
import classes from './Textarea.module.scss';
import classnames from 'classnames';
import { ChangeEventHandler, FocusEventHandler } from 'react';

interface TextareaProps {
  name: string;
  disabled?: boolean;
  text: {
    label: string;
    hint: string;
    placeholder: string;
  };
  hiddenlabel?: boolean;
  rows?: number;
  noWrap?: boolean;
  autoExpand?: boolean;
  'aria-label'?: string;
  mask?: boolean;
  readonly?: boolean;
}

const Textarea = ({
  name,
  text,
  hiddenlabel,
  noWrap = false,
  autoExpand = false,
  rows = 1,
  mask = false,
  readonly = false,
  ...rest
}: TextareaProps) => {
  const { register } = useFormContext();
  const { onChange, onBlur, ...registerRest } = register(name);
  const error = useFieldError(name, text);
  const [overrideRows, setOverrideRows] = useState(0);

  const handleChange: ChangeEventHandler<HTMLTextAreaElement> = async (e) => {
    if (autoExpand) {
      const text = e.target.value;
      const numRows = text.split('\n').length;
      setOverrideRows(numRows);
    }
    await onChange(e);
  };

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = async (e) => {
    if (autoExpand) {
      setOverrideRows(1);
    }
    await onBlur(e);
  };

  const handleFocus: FocusEventHandler<HTMLTextAreaElement> = (e) => {
    if (autoExpand) {
      const text = e.target.value;
      const numRows = text.split('\n').length;
      setOverrideRows(numRows);
    }
  };

  return (
    <label>
      {!hiddenlabel && <div>{text.label}</div>}
      <textarea
        {...rest}
        onFocus={handleFocus}
        onBlur={handleBlur}
        rows={overrideRows > 0 ? overrideRows : rows}
        onChange={handleChange}
        disabled={readonly}
        className={classnames(classes.textarea, {
          [classes.textareaError]: !!error,
          [classes.noWrap]: noWrap,
          [classes.mask]: mask,
        })}
        data-testid={name}
        {...registerRest}
      ></textarea>
      {!!error && <div className={classes.error}>{error}</div>}
    </label>
  );
};

export default Textarea;
