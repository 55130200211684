import { useEffect } from 'react';
import classes from './InsightsHub.module.scss';
export const InsightsHub: React.FC = () => {
  const user = useUser();
  useEffect(() => {
    if (!user) return;
    const script = document.createElement('script');
    const timeoutId = setTimeout(() => {
      script.src = 'https://insightshub.maersk-digital.net/embed/embed.js';
      script.async = true;
      document.body.appendChild(script);
    }, 1000);

    return () => {
      clearTimeout(timeoutId); // Cleanup timeout on component unmount
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      className={classes.asSidetabButton}
      title="Leave feedback"
      data-anchor-sidetab="b4ee9e11-e72a-4ca6-9c20-8c6af29c2eea"
      data-anchor-button-text="Leave feedback"
      data-anchor-user-email={user.email}
    >
      Leave feedback
    </button>
  );
};
