import { McButton } from '@maersk-global/mds-react-wrapper/components-core/mc-button';
import classes from './EmptyResult.module.scss';
import { ReactNode } from 'react';

interface EmptyResultProps {
  children: ReactNode;
  cta?: React.ReactNode;
  onCreateClick?: () => void;
  isEditMode?: boolean;
  buttonProps?: React.ComponentProps<typeof McButton> & {
    'data-testid': string;
  };
}

const EmptyResult = ({
  children,
  cta,
  onCreateClick,
  isEditMode,
  buttonProps,
}: EmptyResultProps) => {
  return (
    <div className={classes.container}>
      <div className={`mds-headline--x-small ${classes.empty}`}>{children}</div>
      {isEditMode && cta && (
        <McButton
          click={onCreateClick}
          fit={'small'}
          variant="outlined"
          appearance="primary"
          {...buttonProps}
        >
          {cta}
        </McButton>
      )}
    </div>
  );
};

export default EmptyResult;
