/* tslint:disable */
/* eslint-disable */
/**
 * Maersk Developer Platform
 * This is a base specification for the Retina (kafka) provisioning API.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: mdp@maersk.onmicrosoft.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { SecretsStorageGithubAuthRoleV1Dto } from './SecretsStorageGithubAuthRoleV1Dto';
import {
    SecretsStorageGithubAuthRoleV1DtoFromJSON,
    SecretsStorageGithubAuthRoleV1DtoFromJSONTyped,
    SecretsStorageGithubAuthRoleV1DtoToJSON,
} from './SecretsStorageGithubAuthRoleV1Dto';
import type { SecretsStorageKvBackendV1Dto } from './SecretsStorageKvBackendV1Dto';
import {
    SecretsStorageKvBackendV1DtoFromJSON,
    SecretsStorageKvBackendV1DtoFromJSONTyped,
    SecretsStorageKvBackendV1DtoToJSON,
} from './SecretsStorageKvBackendV1Dto';

/**
 * 
 * @export
 * @interface SecretsStorageSpecV1Dto
 */
export interface SecretsStorageSpecV1Dto {
    /**
     * List of KV (Version 2) secret backends created for this application
     * @type {Array<SecretsStorageKvBackendV1Dto>}
     * @memberof SecretsStorageSpecV1Dto
     */
    readonly kvSecretBackends?: Array<SecretsStorageKvBackendV1Dto>;
    /**
     * List of github authentication roles to create
     * @type {Array<SecretsStorageGithubAuthRoleV1Dto>}
     * @memberof SecretsStorageSpecV1Dto
     */
    githubAuthRoles?: Array<SecretsStorageGithubAuthRoleV1Dto>;
}

/**
 * Check if a given object implements the SecretsStorageSpecV1Dto interface.
 */
export function instanceOfSecretsStorageSpecV1Dto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function SecretsStorageSpecV1DtoFromJSON(json: any): SecretsStorageSpecV1Dto {
    return SecretsStorageSpecV1DtoFromJSONTyped(json, false);
}

export function SecretsStorageSpecV1DtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): SecretsStorageSpecV1Dto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'kvSecretBackends': !exists(json, 'kvSecretBackends') ? undefined : ((json['kvSecretBackends'] as Array<any>).map(SecretsStorageKvBackendV1DtoFromJSON)),
        'githubAuthRoles': !exists(json, 'githubAuthRoles') ? undefined : ((json['githubAuthRoles'] as Array<any>).map(SecretsStorageGithubAuthRoleV1DtoFromJSON)),
    };
}

export function SecretsStorageSpecV1DtoToJSON(value?: SecretsStorageSpecV1Dto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'githubAuthRoles': value.githubAuthRoles === undefined ? undefined : ((value.githubAuthRoles as Array<any>).map(SecretsStorageGithubAuthRoleV1DtoToJSON)),
    };
}

