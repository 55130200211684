/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GenericOptionResponseDto } from './GenericOptionResponseDto';
import {
    GenericOptionResponseDtoFromJSON,
    GenericOptionResponseDtoFromJSONTyped,
    GenericOptionResponseDtoToJSON,
} from './GenericOptionResponseDto';

/**
 * 
 * @export
 * @interface OptionResponseDto
 */
export interface OptionResponseDto {
    /**
     * 
     * @type {Array<GenericOptionResponseDto>}
     * @memberof OptionResponseDto
     */
    strategicIntents?: Array<GenericOptionResponseDto>;
    /**
     * 
     * @type {Array<GenericOptionResponseDto>}
     * @memberof OptionResponseDto
     */
    mipLayers?: Array<GenericOptionResponseDto>;
    /**
     * 
     * @type {Array<GenericOptionResponseDto>}
     * @memberof OptionResponseDto
     */
    informationClassifications?: Array<GenericOptionResponseDto>;
}

/**
 * Check if a given object implements the OptionResponseDto interface.
 */
export function instanceOfOptionResponseDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function OptionResponseDtoFromJSON(json: any): OptionResponseDto {
    return OptionResponseDtoFromJSONTyped(json, false);
}

export function OptionResponseDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OptionResponseDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'strategicIntents': !exists(json, 'strategicIntents') ? undefined : ((json['strategicIntents'] as Array<any>).map(GenericOptionResponseDtoFromJSON)),
        'mipLayers': !exists(json, 'mipLayers') ? undefined : ((json['mipLayers'] as Array<any>).map(GenericOptionResponseDtoFromJSON)),
        'informationClassifications': !exists(json, 'informationClassifications') ? undefined : ((json['informationClassifications'] as Array<any>).map(GenericOptionResponseDtoFromJSON)),
    };
}

export function OptionResponseDtoToJSON(value?: OptionResponseDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'strategicIntents': value.strategicIntents === undefined ? undefined : ((value.strategicIntents as Array<any>).map(GenericOptionResponseDtoToJSON)),
        'mipLayers': value.mipLayers === undefined ? undefined : ((value.mipLayers as Array<any>).map(GenericOptionResponseDtoToJSON)),
        'informationClassifications': value.informationClassifications === undefined ? undefined : ((value.informationClassifications as Array<any>).map(GenericOptionResponseDtoToJSON)),
    };
}

