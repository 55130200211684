import {
  PageDetailsBody,
  PageDetailsColumn,
  PageType,
  PageActions,
} from '@/components/PageHeader/PageHeader';
import { PageDetailsHead } from '@/components/PageHeader/PageHeader';
import { PageDetailsRow } from '@/components/PageHeader/PageHeader';
import { PageDetails } from '@/components/PageHeader/PageHeader';
import { PageName } from '@/components/PageHeader/PageHeader';
import PageHeader from '@/components/PageHeader/PageHeader';
import { getAppUrl } from '@/helpers/urls';
import { McLoadingIndicator } from '@maersk-global/mds-react-wrapper/components-core/mc-loading-indicator';
import useSWR, { Key } from 'swr';
import classes from './EventProcessingSchemaPage.module.scss';
import PageBody from '@/components/PageBody/PageBody';
import { SchemaForm } from '@/components/EventsProcessing/SchemaForm/SchemaForm';
import { SchemaV1Dto } from '@@/generated/openapi';
import useSWRMutation from 'swr/mutation';
import { waitForPipeline } from '@/helpers/waitForPipeline';
import { useToast } from '@/providers/ToastProvider';
import DeleteResource from '@/components/EventsProcessing/DeleteResource/DeleteResource';

export const EventProcessingSchemaPage = () => {
  const { app, env, schema } = useParams();
  const client = useApiClient();
  const { data: schemaDetails, isLoading } = useSWR(
    ['schemas', app, env, schema],
    () => client.getSchemaV1(app ?? '', env ?? '', schema ?? ''),
  );

  const { showToast } = useToast();

  const { trigger } = useSWRMutation(
    ['schemas', app, env, schema],
    async (_key: Key, { arg: data }: { arg: SchemaV1Dto }) => {
      data.revisionId = schemaDetails?.revisionId ?? '';
      return await waitForPipeline(
        () => client.updateSchemaV1(data, app ?? '', env ?? '', schema ?? ''),
        (id) => client.getPipeline(id),
        (pipelineResponse) =>
          client.getSchemaV1(
            app ?? '',
            env ?? '',
            typeof pipelineResponse.status === 'object' &&
              'steps' in pipelineResponse.status
              ? pipelineResponse.status.steps[1].message?.context?.schemaId ||
                  ''
              : '',
          ),
      );
    },
  );

  if (isLoading) {
    return <McLoadingIndicator variant="bar" />;
  }

  const onSubmit = async (data: SchemaV1Dto) => {
    try {
      await trigger({ ...data, revisionId: schemaDetails?.revisionId });
      showToast({
        message: 'Schema updated successfully',
        appearance: 'success',
      });
    } catch (error) {
      showToast({
        message: 'Error updating schema',
        appearance: 'error',
      });
      throw error;
    }
  };

  return (
    <>
      <PageHeader>
        <PageType>Schema</PageType>
        <PageName>
          <span>{schemaDetails?.name}</span>
        </PageName>
        <PageDetails>
          <PageDetailsRow>
            <PageDetailsColumn>
              <PageDetailsHead>App</PageDetailsHead>
              <PageDetailsBody>
                <Link to={getAppUrl({ app, env })}>{app}</Link>
              </PageDetailsBody>
            </PageDetailsColumn>
            <PageDetailsColumn>
              <PageDetailsHead>Environment</PageDetailsHead>
              <PageDetailsBody>{env}</PageDetailsBody>
            </PageDetailsColumn>
            <PageDetailsColumn>
              <PageDetailsHead>Schema type</PageDetailsHead>
              <PageDetailsBody>{schemaDetails?.type}</PageDetailsBody>
            </PageDetailsColumn>
          </PageDetailsRow>
          <PageDetailsRow>
            <PageDetailsColumn>
              <PageDetailsHead>Resource ID</PageDetailsHead>
              <PageDetailsBody>{schemaDetails?.id}</PageDetailsBody>
            </PageDetailsColumn>
          </PageDetailsRow>
        </PageDetails>
      </PageHeader>
      {app && env && schema && (
        <PageActions>
          <DeleteResource
            resourceType="schema"
            resourceId={schema}
            mutateKey={['schemas', app, env]}
          />
        </PageActions>
      )}
      <PageBody className={classes.pageBody} title="Schema" padding>
        <SchemaForm
          mode="edit"
          onSubmit={onSubmit}
          initialValues={schemaDetails}
        />
      </PageBody>
    </>
  );
};
