import classes from './UserBadge.module.scss';
import classnames from 'classnames';
import {
  IdentityV1Dto,
  IdentityV1DtoTypeEnum,
} from '@@/generated/mtts/openapi';
import { McIcon } from '@maersk-global/mds-react-wrapper/components-core/mc-icon';
import { IMsalContext, useMsal } from '@azure/msal-react';
import useSWR from 'swr';

export enum UserBadgeFit {
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

interface UserBadgeProps {
  identity?: IdentityV1Dto;
  fit?: UserBadgeFit;
}

const msGraphFetcher = async (url: string, msal: IMsalContext) => {
  const token = await msal.instance.acquireTokenSilent({
    scopes: ['user.read'],
    account: msal.accounts[0],
  });
  const result = await fetch(url, {
    headers: { authorization: `Bearer ${token.accessToken ?? ''}` },
  });
  return await result.blob();
};

const useMsGraphSwr = (url: string) => {
  const msal = useMsal();

  return useSWR(url, () => msGraphFetcher(url, msal), {
    onErrorRetry: () => false,
    refreshInterval: 0,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    revalidateOnMount: false,
  });
};

const UserBadge = ({ identity, fit = UserBadgeFit.Medium }: UserBadgeProps) => {
  const { data: image } = useMsGraphSwr(
    `https://graph.microsoft.com/v1.0/users/${identity?.id}/photo/$value`,
  );

  const nameSplit = identity?.displayName?.split(' ') ?? [];
  const initials = `${nameSplit[0]?.substring(0, 1) ?? ''}${
    nameSplit[1]?.substring(0, 1) ?? ''
  }`;

  if (!identity) {
    return null;
  }

  return (
    <>
      {identity.type === IdentityV1DtoTypeEnum.User ? (
        <div className={classnames(classes.initialsCont, classes[fit])}>
          {image?.size && image?.size > 500 ? (
            <img
              alt={identity.displayName}
              className={classes.initials}
              src={image ? URL.createObjectURL(image) : ''}
              width="32"
              height="32"
            />
          ) : (
            <span className={classes.initials}>{initials}</span>
          )}
        </div>
      ) : (
        <div className={classes.icon} slot={'trigger'}>
          <McIcon icon={'computer'} color={'#fff'} />
        </div>
      )}
    </>
  );

  // return <img src={image} />;
};

export default UserBadge;
